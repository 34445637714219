/* Make Swiper buttons smaller */
.swiper-button-next,
.swiper-button-prev {
  width: 30px; /* Control the button size */
  height: 30px; /* Ensure it's a perfect circle */
  background-color: rgba(30, 58, 138, 0.8); /* Background color */
  border-radius: 50%; /* Circular shape */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Reduce the size of the SVG arrow inside the button */
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px; /* Adjust the arrow size */
  color: #fdfeff;
}

/* Hover effect on Swiper buttons */
.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #2563eb; /* Brighter blue on hover */
  transform: scale(1.1); /* Slight zoom effect */
}

/* Custom positioning of buttons */
.swiper-button-prev {
  left: 10px; /* Adjust position */
}

.swiper-button-next {
  right: 10px; /* Adjust position */
}
